<template>
    <div class="orderWaitRefund">
        <el-card class="box-card father-card" shadow="never">
            <el-row class="btns">
                <el-form ref="form" :model="form" inline>
                    <el-form-item label="订单编号">
                        <el-input
                            v-model.trim="form.orderNo"
                            placeholder="请输入订单编号"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="退款人">
                        <el-input
                            v-model.trim="form.memberName"
                            placeholder="请输入用户姓名"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="电话号码">
                        <el-input
                            v-model.trim="form.memberMobile"
                            placeholder="请输入手机号码"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="支付流水号">
                        <el-input
                            v-model.trim="form.payno"
                            placeholder="请输入支付流水号"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="订单开始结束时间">
                        <WTimer ref="WTimer" v-model="form" :startEnd="{start:'starOrderDate',end:'endOrderDate'}" />
                    </el-form-item>
                    <!-- <el-form-item label="订单开始时间" prop="disinfectTime">
                        <el-date-picker
                            v-model="form.starOrderDate"
                            type="datetime"
                            placeholder="开始时间"
                            label-width="10px"
                            clearable
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="到" prop="disinfectTime">
                        <el-date-picker
                            v-model="form.endOrderDate"
                            type="datetime"
                            placeholder="结束时间"
                            clearable
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                        >
                        </el-date-picker>
                    </el-form-item> -->
                    <!-- 按钮区域 -->
                    <el-form-item>
                        <el-button
                            type="success"
                            icon="el-icon-search"
                            @click="search()"
                            >搜索</el-button
                        >
                        <el-button
                            type="primary"
                            icon="el-icon-delete"
                            @click="reset()"
                            >重置</el-button
                        >
                    </el-form-item>
                </el-form>
            </el-row>
            <el-row class="tab">
                <!-- 表格区域 -->
                <el-table
                    ref="multipleTable"
                    :data="List"
                    tooltip-effect="dark"
                    style="width: 100%"
                    highlight-current-row
                    stripe
                    border
                    :header-cell-style="rowClass"
                >
                    <el-table-column
                        prop="orderNo"
                        label="订单编号"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="payNo"
                        label="支付流水号"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="payChannel"
                        label="支付渠道"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <span
                                effect="dark"
                                type="success"
                                v-if="scope.row.payChannel === '1'"
                                >{{ '微信' }}</span
                            >
                            <span
                                effect="dark"
                                v-else-if="scope.row.payChannel === '2'"
                                >{{ '支付宝' }}</span
                            >
                            <span
                                effect="dark"
                                type="warning"
                                v-else-if="scope.row.payChannel === '3'"
                                >{{ '账户余额' }}
                            </span>
                            <span type="danger" effect="dark" v-else-if="scope.row.payChannel === '4'">{{
                                '二维码'
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="payType"
                        label="支付类型"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <span
                                effect="dark"
                                type="warning"
                                v-if="scope.row.payType === '0'"
                                >{{ '预付' }}</span
                            >

                             <span type="success" effect="dark" v-else-if="scope.row.payType === '1'">{{
                                '完成支付'
                            }}</span>
                            <span type="success" effect="dark" v-else-if="scope.row.payType === '2'" >{{"补充收款"}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="payFee"
                        label="支付费用"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="payTime"
                        label="支付时间"
                        align="center"
                    ></el-table-column>
                     <el-table-column
                        prop="statusDesc"
                        label="订单状态"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <span :class="{'active':scope.row.orderStatus==5}">{{scope.row.statusDesc}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="memberName"
                        label="申请退款人"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="memberMobile"
                        label="退款人电话"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="isRefund"
                        label="退款状态"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <span
                                effect="dark"
                                type="warning"
                                v-if="scope.row.isRefund === '0'"
                                >{{ '未退款' }}</span
                            >
                            <span type="info" effect="dark" v-else>{{
                                '已退款'
                            }}</span>
                        </template>
                    </el-table-column>
                      <el-table-column
                        prop="remark"
                        label="备注"
                        align="center"
                    ></el-table-column>
                    <el-table-column width="150" label="详情" align="center">
                        <template slot-scope="scope">
                            <el-button
                            v-if="scope.row.statusDesc=='取消订单'||scope.row.statusDesc=='完成'||scope.row.statusDesc=='提前完成'"
                                size="mini"
                                @click="open(scope.row,true)"
                                type="warning"
                                >申请退款</el-button
                            >
                            <el-button
                            v-if="scope.row.statusDesc=='取消订单'||scope.row.statusDesc=='完成'||scope.row.statusDesc=='提前完成'"
                                size="mini"
                                @click="open(scope.row,false)"
                                type="warning"
                                >退押金</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页区域 -->
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handlePageNumChange"
                    :current-page="paging.PageNo"
                    :page-sizes="[10, 15, 20, 50]"
                    :page-size="paging.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                ></el-pagination>
                <!-- 订单详情弹出框 -->
                <el-dialog
                    :before-close="handleClose"
                    :visible.sync="orderDialogFormVisible"
                    class="order-dialog"
                >
                    <span slot="title" class="dialog-Title">订单退款详情</span>
                    <el-form
                        :model="refundForm"
                        ref="orderForm"

                        :rules="orderFormRules"
                        @close="orderClose"
                    >
                        <!-- 第一行 -->
                        <el-row class="dialog-Title" :style="{ display: screenWidth < 1000 ? 'inline' : 'flex' }">
                            <el-form-item label="支付渠道">
                                {{ refundForm.payReturn }}
                            </el-form-item>
                            <el-form-item label="支付金额">
                                {{ refundForm.payFee }}
                            </el-form-item>
                            <el-form-item
                                label="支付类型"
                                v-if="refundForm.payType == 0"
                            >
                                预付
                            </el-form-item>
                            <el-form-item
                                label="支付类型"
                                v-if="refundForm.payType == 1"
                            >
                                完成支付
                            </el-form-item>
                            <el-form-item
                                label="支付类型"
                                v-if="refundForm.payType == 2"
                            >
                                补充收款
                            </el-form-item>
                        </el-row>
                        <div :style="{display:screenWidth < 1000 ? 'inline' : 'flex'}">
                                <el-form-item :style="{marginRight:screenWidth < 1000 ? '0' : '10px'}" style="flex: 1" label="退款金额" prop="refundFee">
                                    <el-input
                                        v-model="refundForm.refundFee"
                                        autocomplete="off"
                                        clearable
                                        onkeyup="this.value=this.value.replace(/[, ]/g,'')"
                                        @input="changebargainMoney"
                                    >
                                    </el-input>
                                </el-form-item>

                                <el-form-item style="flex: 1;" label="退款备注" prop="remarks">
                                                    <el-input
                                                        v-model="refundForm.remarks"
                                                        autocomplete="off"
                                                        clearable
                                                    ></el-input>
                                </el-form-item>
                         </div>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="refund"
                            >确 认</el-button
                        >
                    </div>
                </el-dialog>
            </el-row>
        </el-card>
    </div>
</template>

<script>
import store from '@/store/index.js'
import WTimer from '@/components/dateCpn/dataPiceker'

export default {
  name: 'orderRefund',
  components: {
    WTimer
  },
  data () {
    return {
      screenWidth: null,
      List: [], // 表格数据源
      form: {
        orderNo: '',
        memberMobile: '',
        memberName: '',
        payno: '',
        starOrderDate: '',
        endOrderDate: ''
      }, // 搜索表单
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      total: 0, // 总的数据条数
      orderDialogFormVisible: false,
      orderForm: {},
      orderFormRules: {
        refundFee: [
          {
            required: true,
            message: '请输入退款金额',
            trigger: 'blur'
          }
        ]
      }, // 编辑资料表单验证规则
      refundForm: {
        refundFee: '',
        payFee: ''
      }
    }
  },

  created () {
    this.getOrderList()
  },
  mounted () {
    this.screenWidth = window.innerWidth

    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.screenWidth = window.innerWidth
      console.log(this.screenWidth, 'this.screenWidth')
    },

    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getOrderList()
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      this.getOrderList()
    },
    // 获取订单退款
    async getOrderList () {
      const { data: res } = await this.$http.post(
        '/userManageServer/wxMiniPay/wxCanApplyPayRefund',
        {
          pageSize: this.paging.pageSize,
          pageNo: this.paging.PageNo,
          ...this.form,
          headers: {
            AUTH_TYPE: 'orderPay'
          }
        }
      )
      console.log(res)
      if (res.statusCode !== '200') { return this.$message.error('获取订单列表失败') }
      this.List = res.data
      this.total = +res.pageBean.pageDataCount
    },
    // 重置
    reset () {
      this.form.orderNo = ''
      this.form.memberMobile = ''
      this.form.memberName = ''
      this.form.payno = ''
      this.form.starOrderDate = ''
      this.form.endOrderDate = ''
      this.$refs.WTimer.resetFields()
      this.paging.pageSize = 10
      this.paging.PageNo = 1
      this.search()
    },
    // 点击搜索按钮触发
    async search () {
      // 调接口，进行查询
      this.paging.PageNo = 1
      const { data: res } = await this.$http.post(
        '/userManageServer/wxMiniPay/wxCanApplyPayRefund',
        {
          pageSize: this.paging.pageSize,
          pageNo: this.paging.PageNo,
          payNo: this.form.payno,
          orderNo: this.form.orderNo,
          memberMobile: this.form.memberMobile,
          memberName: this.form.memberName,
          payno: this.form.payno,
          starOrderDate: this.form.starOrderDate,
          endOrderDate: this.form.endOrderDate,
          headers: {
            AUTH_TYPE: 'orderPay'
          }
        }
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') { return this.$message.error('查询失败') }
      this.List = res.data
      this.total = +res.pageBean.pageDataCount
      // 判断有输入账号
      //   if (this.form.orderNo === '' && this.form.memberMobile === ''&& this.form.payno === '') {
      //     this.getOrderList()
      //   } else {
      //     // 调接口，进行查询
      //     const { data: res } = await this.$http.post('/userManageServer/wxMiniPay/wxCanApplyPayRefund', {
      //       "pageSize": this.paging.pageSize,
      //       "pageNo": this.paging.PageNo,
      // "payNo": this.form.payno,
      //       "orderNo": this.form.orderNo,
      //       "memberMobile": this.form.memberMobile
      //     })
      //     // 判断请求是否成功
      //     if (res.statusCode !== '200') return this.$message.error('查询失败')
      //     this.List = res.data
      //     this.total = +res.pageBean.pageDataCount
      //   }
    },
    // 点击查看详情按钮触发
    open (v, value) {
      this.orderDialogFormVisible = true
      this.refundForm = v
      this.isallMoney = value
      console.log(this.refundForm)
    },
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.$refs.orderForm.resetFields()
          done()
        })
        .catch(_ => {})
    },
    changebargainMoney (event) {
      // 限制不能输入 00.11 的格式
      if (
        this.refundForm.refundFee.split('')[0] == '0' &&
                this.refundForm.refundFee.split('')[1] != '.'
      ) {
        this.refundForm.refundFee = ''
        return
      }
      this.refundForm.refundFee = event
        .toString()
        .match(/^\d*(\.?\d{0,2})/g)[0]
    },
    // 确认按钮，确认退款
    async refund () {
    //   return false
      if (this.isallMoney) {
        if (Number(this.refundForm.refundFee) != Number(this.refundForm.payFee)) {
          return this.$message.error('退款金额不相等，请重新输入')
        }
      } else {
        if (Number(this.refundForm.refundFee) > Number(this.refundForm.payFee)) {
          return this.$message.error('退款金额不能超过支付金额，请重新输入')
        }
      }
      this.$refs.orderForm.validate(async (valid) => {
        if (!valid) return
        this.refundForm.createBy = sessionStorage.getItem('userId')
        const obj = JSON.parse(JSON.stringify(this.refundForm))
        obj.remark = this.refundForm.remarks
        const { data: res } = await this.$http.post(
          '/userManageServer/wxMiniPay/wxApplyPayRefund',
          {
            ...JSON.parse(JSON.stringify(obj)),
            headers: {
              AUTH_TYPE: 'orderRefund',
              CORP_ID: store.state.order_company || sessionStorage.getItem('order_company')
            }
          }
        )
        console.log(res)
        if (res.statusCode !== '200') { return this.$message.error('申请失败') }
        this.$message.success('申请成功')
        //   this.getOrderList()
        this.search()
        // this.reset(  )
        this.orderDialogFormVisible = false
      })
    },
    orderClose () {
      this.refundForm = {}
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>
<style lang="less" scoped>
.orderWaitRefund {
    width: 100%;
    height: 100%;

    .father-card {
        .btns {
            margin-bottom: 10px;
        }

        .tab {
            .el-pagination {
                margin-bottom: 0.25rem;
                margin-top: 0.25rem;
                margin-right: 0.25rem;
                text-align: right;
            }

            .order-dialog {
                .dialog-Title {
                    color: #0097fe;
                    font-size: 20px;
                    font-weight: 700;
                }

                /deep/ .el-form {
                    .subtitle {
                        color: #0097fe;
                        font-size: 14px;
                        font-weight: 700;
                        margin-bottom: 20px;
                        padding-left: 6px;
                        border-left: 5px solid #0097fe;
                    }

                    .el-row {
                        display: flex;

                        .el-form-item {
                            flex: 1;
                            display: flex;

                            .el-form-item__content {
                                flex: 1;

                                .el-input {
                                    width: 100%;

                                    .el-input__inner {
                                        width: 100%;
                                    }
                                }

                                .el-select {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.active{
    color: red;
}

</style>
